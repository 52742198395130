import { Controller } from "@hotwired/stimulus"
import { application } from "~/controllers/application"
import $ from "jquery"

application.register("contact-form", class extends Controller {
  static targets = ["emailsSelect"]

  updateSelectedOptions() {
    const controller = this
    const select = HSSelect.getInstance(this.emailsSelectTarget);
    $(this.element).find("input.selected-option").remove()
    select.value.forEach(function(val) {
      $(controller.element).append(`<input type="hidden" class="selected-option" name="${controller.emailsSelectTarget.name}" value="${val}">`)
    })
  }
})